::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #0e0e0e;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent
}

.guild-selector {
  border-radius: 4px;
  display: flex;
  height: 60px;
  padding: 10px 20px;
  width: calc(100% - 10px);
  margin: 0 5px;
}

.guild-selector:hover {
  background: rgba(255,255,255,.03);
  text-decoration: none!important;
}

.fullscreen-height {
  height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  @media (orientation: portrait) {
    .fullscreen-height {
      height: -webkit-fill-available
    }
  }
}

@keyframes glow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
}

.logo:before {
  content: "";
  height: 38px;
  width: 38px;
  border-radius: 50%;
  position: absolute;
  animation: glow 5s linear infinite;
  box-shadow: 0 0 88px #5865F2;
}