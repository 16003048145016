@keyframes glow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
}

.logo:before {
  content: "";
  height: 38px;
  width: 38px;
  border-radius: 50%;
  position: absolute;
  animation: glow 5s linear infinite;
  box-shadow: 0 0 88px #5865F2;
}

#content {
  transition: padding .3s;
  padding: 40px;
  /* background: rgb(39, 41, 52); */
  background: rgb(33, 40, 49);
  border-top-left-radius: 4px;
  overflow-y: scroll;
  height: calc(100vh - 80px);
}

#sidebar {
  height: 100%;
  padding: 0 20px;
  /* background: rgb(31, 33, 41); */ 
  background: rgb(27, 32, 40);
  overflow-y: scroll;
}

.sidebar-element {
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 8px;
}

.sidebar-element:not(.sidebar-link):hover {
  /* background: rgb(39, 41, 52); */
  background: #212831;
}

.sidebar-link:hover {
  text-decoration: underline;
}

.sidebar-element-icon {
  width: 24px;
  height: 24px;
}

.sidebar-element:not(.sidebar-element-premium):hover .sidebar-element-text:not(.sidebar-element-premium), .sidebar-element:not(.sidebar-element-premium):hover .sidebar-element-icon:not(.sidebar-element-premium) {
  color: white;
}

.sidebar-element-text {
  font-size: 14px;
  font-weight: 500px;
}

@media (max-width: 1000px) {
  #content {
    padding: 24px
  }
}