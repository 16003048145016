body {
  background: #202123;
  color: white;
  font-family: system-ui
}

#feature-table td {
  padding: 12px;
}

#feature-table tr {
   border-top: 1px solid rgba(255,255,255,.1);
}

#feature-table thead tr {
  border: none
}

#feature-table {
  border-collapse: collapse;
  width: 100%;
}

#feature-table-premium-highlight {
  top: 20px;
  left: 0;
  position: absolute;
  height: 200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: stretch;
  z-index: -1
}

#feature-table-premium-highlight-3 {
  background: rgba(255,255,255,.1);
  border: 1px solid rgba(255,255,255,.2);
  border-radius: 10px;
  margin-right: 17%;
}