@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Thicccboi;
  src: url(fonts/THICCCBOI-Regular.woff2);
  font-weight: normal;
}

@font-face {
  font-family: Thicccboi;
  src: url(fonts/THICCCBOI-Medium.woff2);
  font-weight: 500;
}

@font-face {
  font-family: Thicccboi;
  src: url(fonts/THICCCBOI-SemiBold.woff2);
  font-weight: 600;
}

@font-face {
  font-family: Thicccboi;
  src: url(fonts/THICCCBOI-Bold.woff2);
  font-weight: bold;
}

.useCase:hover > .useCaseIcon {
  /* color: rgb(248, 28, 229); */
  color: #5865F2;
  transition: color .15s ease-in-out;
}

body {
  background: radial-gradient(1124px 720px at 0% 0%, rgb(215, 231, 248) 0%, rgba(222, 231, 248, 0) 100%) no-repeat fixed, rgb(255, 255, 255)!important; 
  background: #181f25!important;
  color: white!important;
}

button.primary {
  background-image: linear-gradient(60deg, #4457ff, #7583fd);
  padding: 30px 42px;
  border-radius: 1000000000px;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 0 0 35px 10px rgba(89, 106, 255, 0.28);
}

button.primary:hover {
  transform: scale3d(0.95, 0.95, 1.01);
  background-image: linear-gradient(60deg, #4457ff, #7583fd);
}

@keyframes glow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
}

.logo:before {
  content: "";
  height: 38px;
  width: 38px;
  border-radius: 50%;
  position: absolute;
  animation: glow 5s linear infinite;
  box-shadow: 0 0 88px #5865F2;
}

.logo-big:before {
  content: "";
  height: 103.5px;
  width: 103.5px;
  border-radius: 50%;
  position: absolute;
  animation: glow 5s linear infinite;
  box-shadow: 0 0 88px #5865F2;
}

nav, #nav-backdrop {
  position: fixed;
  height: 60px;
  top: -60px;
  width: 100%;
  transition: all .2s ease;
}

nav {
  z-index: 99;
  max-width: 1200px;
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  font-size: 1.05em;
  justify-content: space-between;
}

#nav-backdrop {
  z-index: 98;
  backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(255,255,255,.05);
  left: 0;
}

nav.show, #nav-backdrop.show {
  top: 0
}

.opacity-0 {
  opacity: 0;
}

.burger {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  cursor: pointer;
}

.burger:hover {
  opacity: .8;
}

.burger .line {
  height: 2px;
  width: 25px;
  background: white;
  border-radius: 5px;
}